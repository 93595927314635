$(window).scroll(function(){
  var windowWidth = $(window).width();
  var $el = $('body').add('.header');
  if (windowWidth < 1200) {
    $el.removeClass('sticky');
    return;
  }

  if ($(window).scrollTop() > 0) {
    $el.addClass('sticky');
  } else {
    $el.removeClass('sticky');
  }
});
